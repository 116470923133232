/**
 * This is the page that stripe redirects to
 * once the user's oauth onboarding is complete.
 *
 * This page performs the last required stripe request
 * and then updates the user's database entry
 */
import React from 'react'
import { Link, navigate } from 'gatsby'

import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import RedirectForm from 'components/OnboardingPage/RedirectForm'

class RedirectPage extends React.Component {
  state = { error: null }
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search),
      code = params.get('code')
    // state = params.get("state") // TODO: HANDLE CSRF
    if (!code) {
      return navigate('/')
    } else {
      this.finishAuthentication(code)
    }
  }

  async finishAuthentication(code) {
    try {
      await this.props.auth.stripeAuthentication(code)
      navigate('/dashboard/settings')
    } catch (error) {
      console.log(error)
      this.setState({ error: true })
    }
  }

  render() {
    return <RedirectForm error={this.state.error} />
  }
}

export default props => (
  <Layout {...props} displayFooter={false}>
    <SEO />
    <RedirectPage {...props} />
  </Layout>
)
