import React from 'react'

import { Alert, FormContainer } from 'components/shared/Forms'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import { LoadingDots } from 'components/shared/Loading'

export default ({ error }) => (
  <FormContainer>
    <Card title="Signing up for Payments">
      <Alert
        type="error"
        visible={error}
        message="Something has gone wrong with connecting payments. Our team has been notified of the error. Please try again later."
      />
      {!error ? (
        <p>Completing Registration. Please wait to be redirected</p>
      ) : (
        <Button big full to="/dashboard/settings">
          Go to dashboard
        </Button>
      )}
      <LoadingDots loading={!error} />
    </Card>
  </FormContainer>
)
